export const WORDS = [
  'naime',
  'waifu',
  'akron',
  'india',
  'weedy',
  'chess',
  'ganyu',
  'weird',
  'abyss',
  'stars',
  'lemao',
  'comfi',
  'lucky',
  'gacha',
  'omega',
  'dance',
  'terra',
  'ifrit',
  'titan',
  'leech',
  'peepo',
  'snail',
  'madge',
  'hutao',
  'rocks',
  'pulls',
  'bench',
  'among',
  'vtube',
  'surtr',
  'texas',
  'boost',
  'wheel',
  'snipe',
  'fishy',
  'drake',
  'ceobe',
  'ratio',
  'liked',
  'react',
  'clint',
  'sadge',
  'booba',
  'coach',
  'rhine',
  'heart',
  'comfy',
  'troll',
  'leave',
  'saria',
  'drama',
  'catge',
  'blind',
  'steal',
  'squad',
  'monka',
  'leroy',
  'pizza',
  'mitch',
  'shame',
  'beans',
  'borpa',
  'anime',
  'muted',
  'lobby',
  'grind',
  'sussy',
  'primo',
  'sales',
  'yelan',
  'clues',
  'scams',
  'voice',
  'crack',
  'cheat',
  'bedge',
  'stabs',
  'funny',
  'tokyo',
  'walks',
  'train',
  'fever',
  'annoy',
  'autos',
  'joint',
  'maria',
  'nearl',
]
